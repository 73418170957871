@font-face {
  font-family: "Montserrat Black";
  font-weight: 900;
  font-style: normal;
  src: url("#{$norent-root}/font/montserrat-black-webfont.woff2")
      format("woff2"),
    url("#{$norent-root}/font/montserrat-black-webfont.woff") format("woff");
}

@font-face {
  font-family: "Montserrat Bold";
  font-weight: normal;
  font-style: normal;
  src: url("#{$norent-root}/font/montserrat-bold-webfont.woff2") format("woff2"),
    url("#{$norent-root}/font/montserrat-bold-webfont.woff") format("woff");
}

@font-face {
  font-family: "Open Sans";
  font-weight: 200;
  font-style: normal;
  src: url("#{$norent-root}/font/opensans-light-webfont.woff") format("woff");
}

@font-face {
  font-family: "Open Sans";
  font-weight: normal;
  font-style: normal;
  src: url("#{$norent-root}/font/opensans-regular-webfont.woff") format("woff");
}

@font-face {
  font-family: "Open Sans";
  font-weight: 600;
  font-style: normal;
  src: url("#{$norent-root}/font/opensans-semibold-webfont.woff") format("woff");
}

@font-face {
  font-family: "Open Sans";
  font-weight: 900;
  font-style: normal;
  src: url("#{$norent-root}/font/opensans-extrabold-webfont.woff")
    format("woff");
}
