// New style for extra wide/text-wrapped buttons, to accomodate multiple lines of text on mobile:
.button.jf-is-extra-wide,
.button.jf-text-wrap {
  font-size: 1.25rem;
  height: 0;
  padding-left: 4rem;
  padding-right: 4rem;

  @media screen and (max-width: $tablet) {
    width: 100%;
    // Since these buttons can go to 2 or 3 lines on mobile,
    // we want to bring back the internal height,
    // which means we must also reduce the padding to offset:
    height: auto;
    padding: 1.25rem;
  }
}

// For all buttons that aren't extra-wide/text-wrapped, let's set the line-height to 0
// so that our button padding doesn't offset the placement of the inner text
.button:not(.jf-is-extra-wide):not(.jf-text-wrap) {
  line-height: 0;
}

// New style for back buttons:
.button.jf-is-back-button {
  background-color: transparent;
  border: 2px solid $primary;
  color: $primary;

  &:hover,
  &:active {
    background-color: $primary;
    color: $white;
  }
}

.jf-two-buttons {
  > .button {
    max-width: 45%;
  }
  .control,
  form {
    max-width: 45%;
    // This style targets any button wrapped in a control class or form inside a container of two buttons.
    // The main examples of these inside NoRent are the "Cancel" buttons in the first steps of the letter builder.
    .button {
      width: 100%;
    }
  }
  // Add special button justification for the log out button container
  &.jf-log-out-button-container {
    justify-content: flex-end;
  }
}

// Default styling for all "light" buttons other than our standard back button
.button.is-light:not(.jf-is-back-button) {
  color: $white;
  &:hover {
    color: $white;
  }
  // Override color of loading icon:
  &.is-loading::after {
    border-color: transparent transparent $white $white !important;
  }
}
