// Fallbacks taken from:
// http://melchoyce.github.io/fontstacks/examples/open-sans.html

// FONTS:
$family-sans-serif: $jf-body-family;

// COLORS:
$rtc-denim: #041e41;
$rtc-cobalt: #034da6;
$rtc-mulberry: #970048;
$justfix-off-black: #3b403d;
$justfix-light-grey: #c4c4c4;
$justfix-grey: #bdbdbd;

$primary: $rtc-mulberry;
$info: $rtc-denim; // Secondary color for logo and non-text elements
$link: $rtc-cobalt; // Inline hyperlink color
$dark: $justfix-off-black; // Primary text color for light backgrounds
$grey-light: $justfix-light-grey; // Secondary text color for dark backgrounds
$light: $justfix-grey; // Secondary background color for buttons (i.e. Cancel buttons)

// BUTTONS:
$button-padding-vertical: 2rem;
$button-padding-horizontal: 4rem;

// MESSAGES:
$message-body-padding: 3rem;
$message-body-border-width: 0px;

// CONTENT:
$title-color: $dark;
$subtitle-color: $dark;
$content-heading-color: $dark;
$body-color: $dark;

// FORMS:
$label-color: $dark;
$input-color: $dark;

// BOXES:
$box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
