.modal-content {
  p {
    font-size: 1.25rem;
    line-height: 1.3;
  }
  .content {
    @include tablet {
      padding: 2.5rem 4rem;
    }
    h1.title {
      font-size: 2.25rem;
    }
  }
  .button {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }
}
